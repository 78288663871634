import React from "react";
import LogoWhite from "../../images/logo-white.webp";

const Footer = () => {
  return (
    <>
      <footer className="rlt-center">
        <div className="content">
          <div className="social">
            <a href="https://www.facebook.com/groups/1737099776801999/?ref=share&mibextid=NSMWBT">
              <i className="fa-brands fa-facebook"></i>
            </a>
          </div>
          <div className="cr-logo-wrapper">
            <div className="footer-logo">
              <img src={LogoWhite} alt="" />
            </div>
            <div className="cr">
              PreacherCheck: Unmasking Deceit, a collaborative initiative
              empowering the audience to expose lies within preaching circles.
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
