import { useEffect } from "react";
import React from "react";
import { useParams } from "react-router-dom";
import Header from "./subComponents/Header";
import Footer from "./subComponents/Footer";
document.body.classList.remove("proph");
const Logic = () => {
  useEffect(() => {
    // Check if the 'main' element has the 'main-proph' class
    var mainElement = document.querySelector("main");

    if (mainElement && mainElement.classList.contains("main-logic")) {
      // Apply styles to the body element
      document.body.style.cssText +=
        'background: url("https://files.catbox.moe/ztxpj8.jpg") !important; ' +
        "background-size: cover !important; " +
        "background-position: center !important; " +
        "background-attachment: fixed !important;";
    }

    // Cleanup function to reset styles when component unmounts
    return () => {
      document.body.style.cssText = ""; // Reset body styles
    };
  }, []);
  return (
    <>
      <Header />
      <main className="main-logic">
        <div>
          What is logic? This in simple terms is the way you come to reasonable
          conclusions using given principles. Just like how 1+1=2, logic allows
          us to understand ideas and the world around us using the information
          made available to us.Our goal for this website is to help the
          community. And learning about logic is another step to further inform
          the public in avoiding problematic parties and also sharpening their
          thinking. Using simple weekly(hopefully) examples that follow the
          logical principles and fallacies we will be covering. Keep in mind, it
          is advised you do further study around logical principles outside of
          relying on us.We are starting with, logical fallacies! But what is a
          logical fallacy? A fallacy is a wrong idea or concept people add to
          their arguments that falsify the conclusions they arrive to. You can
          think of this like multiplying only one side of an equation by a
          number that is not 1. 1+1 is not= 20. 2 x 10. A fallacy affects the
          conclusion in a similar manner. There are also other terms we need to
          elaborate on. Such as an argument. We do not mean when someone is
          shouting with you, instead it means the sentences you arrange to
          express an idea with a conclusion or answer. It is important to note,
          JUST BECAUSE SOMETHING IS LOGICAL DOES NOT AUTOMATICALLY MAKE IT
          TRUE!! Because logic relies on a set of knowledge, the conclusion
          reached may not reflect reality. For example; “All Zambians are born
          in Zambia or have Zambian parents.” This may be logically sound
          because you know that Zambian citizenship has these requirements.
          However, there is additional information like Marriage that the person
          making this claim does not know! It is unfortunate but people often
          make flaws in logic for a variety of reasons. According to Internet
          Encyclopaedia of Philosophy, “A fallacy is a kind of error in
          reasoning.” They have a list of 231 names of the most common
          fallacies. https://iep.utm.edu/fallacy/ So lets all make an effort to
          be better and think with wisdom.
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Logic;
