// ProphecyDetail.js
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "./subComponents/Header";
import Footer from "./subComponents/Footer";
import PropheciesData from "../propheciesData.json";
import "../CSS/prophecies.css";
document.body.classList.add("proph");

const ProphecyDetail = () => {
  useEffect(() => {
    // Check if the 'main' element has the 'main-proph' class
    var mainElement = document.querySelector("main");

    if (mainElement && mainElement.classList.contains("main-proph")) {
      // Apply styles to the body element
      document.body.style.cssText +=
        'background: url("https://files.catbox.moe/e6b7p7.jpg") !important; ' +
        "background-size: cover !important; " +
        "background-position: center !important; " +
        "background-attachment: fixed !important;";
    }

    // Cleanup function to reset styles when component unmounts
    return () => {
      document.body.style.cssText = ""; // Reset body styles
    };
  }, []);
  const { prophecyId } = useParams();
  const prophecy = PropheciesData[Number(prophecyId)]; // Convert to number

  if (!prophecy) {
    return <div>Prophecy not found</div>;
  }

  return (
    <>
      <Header />
      <main className="prophecy-detail main-proph">
        <div className="title">
          <h2>{prophecy.title}</h2>
        </div>
        <div className="content">
          <div className="prophecy-img">
            <img src={prophecy.img} alt={prophecy.claim} />
          </div>
          <div className="detail-section">
            <h3>Fulfillment</h3>
            <p>{prophecy.fulfillment}</p>
          </div>
          <div className="detail-section">
            <h3>Claim</h3>
            <p>{prophecy.claim}</p>
            {Object.entries(prophecy.sources).map(([index, source]) => (
              <p>
                <a href={prophecy.sourcesLinks[index]}>
                  {prophecy.sources[index]}
                </a>
              </p>
            ))}
          </div>
          <div className="detail-section">
            <h3>Date</h3>
            <p>{prophecy.date}</p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
document.addEventListener("DOMContentLoaded", function () {
  // Check if the 'main' element has the 'main-proph' class
  var mainElement = document.querySelector("main");

  if (mainElement && mainElement.classList.contains("main-proph")) {
    // Apply styles to the body element
    document.body.style.background = 'url("../images/bgi.jpg") !important';
    document.body.style.backgroundSize = "cover !important";
    document.body.style.backgroundPosition = "center !important";
    document.body.style.backgroundAttachment = "fixed";
  }
});

export default ProphecyDetail;
