import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "./subComponents/Header";
import Footer from "./subComponents/Footer";
import PreachersData from "../preachersData.json";
import "../CSS/preachers.css";
document.body.classList.remove("proph");
const Preachers = () => {
  useEffect(() => {
    // Calculate header and footer heights
    const headerHeight = document.querySelector("header").offsetHeight;
    const footerHeight = document.querySelector("footer").offsetHeight;

    // Set min-height of main element
    document.querySelector(
      "main"
    ).style.minHeight = `calc(100vh - (${headerHeight}px + ${footerHeight}px))`;
  }, []); // Empty dependency array ensures that this effect runs only once after the initial render

  return (
    <>
      <Header />
      <main className="main-center">
        <div className="preachers-list">
          <div className="title">
            <h2>Preachers</h2>
          </div>
          <div className="list">
            <div className="content">
              {Object.values(PreachersData).map((el, index) => (
                <div key={index} className="preacher-item">
                  <div className="preacher-pfp">
                    <img src={el.img} alt={el.name} />
                  </div>
                  <div className="preacher-name">{el.name}</div>
                  <div className="more">
                    <Link to={`/preachers/${index}`}>
                      <button className="btn-primary">More</button>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Preachers;
