import React from "react";
import Header from "./subComponents/Header";
import Footer from "./subComponents/Footer";
import "../CSS/contact.css";
document.body.classList.remove("proph");
export default function Prophecies() {
  return (
    <>
      <Header />
      <main>
        <form
          action="https://formsubmit.co/hunterdzz23@gmail.com"
          className="contact"
          method="POST"
          encType="multipart/form-data"
        >
          <input
            type="hidden"
            name="_next"
            value="https://preachercheck.com/#/thanks"
          ></input>
          <input type="hidden" name="_template" value="table" />
          <div className="pinfo">
            <div className="input">
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Enter your name"
                required
              />
            </div>
            <div className="input">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your email address"
                required
              />
            </div>
          </div>
          <div className="msg">
            <div className="input">
              <input
                type="text"
                name="message-subject"
                id="subject"
                placeholder="Subject of message"
                required
              />
            </div>
            <div className="input">
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="10"
                placeholder="Enter your message"
                required
              ></textarea>
            </div>
          </div>
          <input type="file" name="file" id="file" accept="image/*" />
          <button type="submit" className="primary-btn">
            <i className="fa-regular fa-paper-plane"></i> Submit
          </button>
        </form>
      </main>
      <Footer />
    </>
  );
}
