import React, { useState } from "react";
import Logo from "../../images/logo.webp";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMobileNavShown, setMobileNavShown] = useState(false);

  const mobileNav = () => {
    setMobileNavShown(!isMobileNavShown);
  };

  const closeMobileNav = () => {
    setMobileNavShown(false);
  };

  return (
    <>
      <div
        className={`overlay ${isMobileNavShown ? "shown" : ""}`}
        onClick={closeMobileNav}
      ></div>

      {/* Mobile Links */}
      <div className={`mobile-links ${isMobileNavShown ? "shown" : ""}`}>
        <div className="mobile-nav-title">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
          <h3>Menu</h3>
          <div className="close" onClick={closeMobileNav}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/prophecies">Prophecies</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/preachers">Preachers</Link>
          </li>
          <li>
            <Link to="/logic">Logic</Link>
          </li>
        </ul>
      </div>

      {/* Header Container */}
      <div className="header-container">
        <header className="rlt-center">
          <div className="content">
            {/* Mobile Navigation */}
            <div className="mobile-nav">
              <div className="toggle" onClick={mobileNav}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </div>
            </div>

            {/* Logo */}
            <div className="logo">
              <img src={Logo} alt="logo" />
            </div>

            {/* Navigation */}
            <nav>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/prophecies">Prophecies</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/preachers">Preachers</Link>
                </li>
                <li>
                  <Link to="/logic">Logic</Link>
                </li>
              </ul>
            </nav>

            {/* Contact Button */}
            <div className="icon">
              <Link to="/contact">
                <button className="btn-primary contact-btn">Contact</button>
              </Link>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default Header;
