import React from "react";
import Header from "./subComponents/Header";
import Footer from "./subComponents/Footer";
import "../CSS/about.css";
document.body.classList.remove("proph");
export default function About() {
  return (
    <>
      <Header />
      <main className="main-fix">
        <div className="about">
          <div className="about--text">
            <h2>Who are we</h2>
            <p>
              We established this site in order to monitor claimed "Men and
              woman of God" who consistently use scripture to benefit themselves
              and display questionable morals and decisions not befitting of
              people with their title. Anyone can claim to be a prophet and
              anyone can run a church, we understand this and hold no issues
              with such persons until they cross the line and affect the
              community negatively. Its bad enough that you give a random person
              your money, might as well ensure they aren't bad people.
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
