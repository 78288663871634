// PreacherDetail.js
import React from "react";
import { useParams } from "react-router-dom";
import Header from "./subComponents/Header";
import Footer from "./subComponents/Footer";
import PreachersData from "../preachersData.json";
document.body.classList.remove("proph");
const PreacherDetail = () => {
  const { preacherId } = useParams();
  const preacher = PreachersData[Number(preacherId)]; // Convert to number

  if (!preacher) {
    return <div>Preacher not found</div>;
  }

  return (
    <>
      <Header />
      <main className="preacher-detail">
        <div className="title">
          <h2>{preacher.name}</h2>
        </div>
        <div className="content">
          <div className="preacher-pfp">
            <img src={preacher.img} alt={preacher.name} />
          </div>
          <div className="detail-section">
            <h3>Scandals</h3>
            {preacher.scandals.map((scandal, index) => (
              <div key={index}>
                <p>{scandal}</p>
                <p>
                  {preacher.scandalsSource && preacher.scandalsSourceLink && (
                    <a
                      href={preacher.scandalsSourceLink[index]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {preacher.scandalsSource[index] || "Click here"}
                    </a>
                  )}
                </p>
              </div>
            ))}
          </div>
          <div className="detail-section">
            <h3>Current State</h3>
            <p>{preacher.currentState}</p>
          </div>
          <div className="detail-section">
            <h3>Preacher's Statement</h3>
            {preacher.statements.map((statement, index) => (
              <div key={index}>
                <p>{statement}</p>
                <p>
                  {preacher.statementsSource[index] &&
                    preacher.statementsSourceLink[index] && (
                      <a
                        href={preacher.statementsSourceLink[index]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {preacher.statementsSource[index] || "Click here"}
                      </a>
                    )}
                </p>
              </div>
            ))}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default PreacherDetail;
