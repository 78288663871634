import React, { useState } from "react";
import Header from "./subComponents/Header";
import Footer from "./subComponents/Footer";
import Hero from "../images/hero.webp";
import "../CSS/home.css";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

export default function Prophecies() {
  const [showDisclaimer, setShowDisclaimer] = useState(true);

  const handleRemoveDisclaimer = () => {
    setShowDisclaimer(false);
  };

  return (
    <>
      <CSSTransition
        in={showDisclaimer}
        timeout={500}
        classNames="disclaimer-popup"
        unmountOnExit
      >
        <div className="disclaimer-popup">
          <div className="disclaimer-content">
            <p>
              Disclaimer: "We the preachercheck team, do not use this site for
              any other means aside from critic and to provide useful
              information to prevent the abuse of church goers from questionable
              characters. We absolutely do not condone the harassment or further
              persecution of anyone featured on this website, and recommend
              seeking legal counsel if a grievance has been experienced rather
              than taking justice into your own hands."
            </p>
            <button className="btn-primary" onClick={handleRemoveDisclaimer}>
              Continue
            </button>
          </div>
        </div>
      </CSSTransition>

      <Header />
      <main>
        <div className="hero rlt-center">
          <div className="content">
            <div className="hero-text">
              <h2>Christians?</h2>
              <p>Can you really trust your preacher?</p>
              <button className="btn-primary">
                <a href="#">Find Out More</a>
              </button>
            </div>
          </div>
          <div className="hero-img">
            <img src={Hero} alt="" />
          </div>
        </div>
        <div className="intro section">
          Keep an eye out for double standards among some preachers. Actions
          often speak louder than words, and it's worth questioning if they
          practice what they preach. Challenge the authenticity you see, and
          don't be afraid to question their integrity. Stay sharp on your
          spiritual journey, and trust your instincts.
        </div>
        <div className="give-us-info">
          <h1>Tell Us What You Know</h1>
          <p>
            Have compelling stories to share? Perhaps an encounter with a
            preacher or an experience that challenges certain perspectives?
            Reach out to us, and we'll feature it on our site.
          </p>
          <Link to="/contact">
            <button className="btn-primary">Contact Us</button>
          </Link>
        </div>
      </main>
      <Footer />
    </>
  );
}
