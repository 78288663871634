import React from "react";
import Header from "./subComponents/Header";
import Footer from "./subComponents/Footer";
import { Link } from "react-router-dom";
import "../CSS/about.css";
document.body.classList.remove("proph");
export default function Thanks() {
  return (
    <>
      <Header />
      <main className="main-fix">
        <div className="about">
          <div className="about--text">
            <h2>Thanks for Contacing us</h2>
            <p>We will review your message as soon as possible</p>

            <Link to="/">
              <button className="btn-primary">Go back</button>
            </Link>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
