// Prophecies.js
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "./subComponents/Header";
import Footer from "./subComponents/Footer";
import PropheciesData from "../propheciesData.json";
import "../CSS/prophecies.css";
const Prophecies = () => {
  useEffect(() => {
    // Check if the 'main' element has the 'main-proph' class
    var mainElement = document.querySelector("main");

    if (mainElement && mainElement.classList.contains("main-proph")) {
      // Apply styles to the body element
      document.body.style.cssText +=
        'background: url("https://files.catbox.moe/e6b7p7.jpg") !important; ' +
        "background-size: cover !important; " +
        "background-position: center !important; " +
        "background-attachment: fixed !important;";
    }

    // Cleanup function to reset styles when component unmounts
    return () => {
      document.body.style.cssText = ""; // Reset body styles
    };
  }, []);
  useEffect(() => {
    // Calculate header and footer heights
    const headerHeight = document.querySelector("header").offsetHeight;
    const footerHeight = document.querySelector("footer").offsetHeight;

    // Set min-height of main element
    document.body.style.background = 'url("../images/bgi.jpg") !important';
    document.querySelector(
      "main"
    ).style.minHeight = `calc(100vh - (${headerHeight}px + ${footerHeight}px))`;
  }, []); // Empty dependency array ensures that this effect runs only once after the initial render
  // Check if the 'main' element has the 'main-proph' class

  return (
    <>
      <Header />
      <main className="main-center main-proph">
        <div className="verse">
          <blockquote>
            “Watch out for false prophets. They come to you in sheep’s clothing,
            but inwardly they are ferocious wolves. By their fruit, you will
            recognize them. Do people pick grapes from thornbushes, or figs from
            thistles? Likewise, every good tree bears good fruit, but a bad tree
            bears bad fruit. A good tree cannot bear bad fruit, and a bad tree
            cannot bear good fruit. Every tree that does not bear good fruit is
            cut down and thrown into the fire. Thus, by their fruit, you will
            recognize them.”
          </blockquote>
        </div>
        <div className="prophecies-list">
          <div className="title">
            <h2>Prophecies</h2>
          </div>
          <div className="list">
            <div className="content">
              {Object.values(PropheciesData).map((el, index) => (
                <div key={index} className="prophecy-item">
                  <div className="prophecy-img">
                    <img src={el.img} alt={el.title} />
                  </div>
                  <div className="prophecy-title">{el.title}</div>
                  <div className="more">
                    <Link to={`/prophecies/${index}`}>
                      <button className="btn-primary">More</button>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Prophecies;
