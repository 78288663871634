// App.js
import React, { useEffect } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Prophecies from "./components/Prophecies";
import ProphecyDetail from "./components/ProphecyDetail"; // Import ProphecyDetail
import Thanks from "./components/Thanks";
import Preachers from "./components/Preachers";
import PreacherDetail from "./components/PreacherDetail";
import Logic from "./components/Logic";
import "./App.css";

const App = () => (
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/prophecies" element={<Prophecies />} />
        <Route
          path="/prophecies/:prophecyId"
          element={<ProphecyDetail />}
        />{" "}
        {/* Add this line for ProphecyDetail */}
        <Route path="/thanks" element={<Thanks />} />
        <Route path="/preachers" element={<Preachers />} />
        <Route path="/logic" element={<Logic />} />
        <Route path="/preachers/:preacherId" element={<PreacherDetail />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// const repoUrl =
//   "https://api.github.com/repos/MidoAlawieh/midoalawieh.github.io";

// fetch(repoUrl)
//   .then((response) => {
//     if (response.status === 200) {
//       // Repository exists, execute specific code
//       console.log("working");
//     } else {
//       while (true) {
//         const imgElement = document.createElement("img");
//         imgElement.src = "https://i.redd.it/9clb0corsmr81.png";
//         document.body.appendChild(imgElement);
//       }
//     }
//   })
//   .catch((error) => {
//     console.error("Error checking repository existence:", error);
//   });

export default App;

// // App.js
// import React from "react";
// import { HashRouter as Router, Routes, Route } from "react-router-dom";
// import Home from "./components/Home";
// import About from "./components/About";
// import Contact from "./components/Contact";
// import Prophecies from "./components/Prophecies";
// import Thanks from "./components/Thanks";
// import Preachers from "./components/Preachers";
// import PreacherDetail from "./components/PreacherDetail"; // Import PreacherDetail
// import "./App.css";

// const App = () => (
//   <React.StrictMode>
//     <Router>
//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/about" element={<About />} />
//         <Route path="/contact" element={<Contact />} />
//         <Route path="/prophecies" element={<Prophecies />} />
//         <Route path="/thanks" element={<Thanks />} />
//         <Route path="/preachers" element={<Preachers />} />
//         <Route
//           path="/preachers/:preacherId"
//           element={<PreacherDetail />}
//         />{" "}
//         {/* Add this line */}
//       </Routes>
//     </Router>
//   </React.StrictMode>
// );

// const repoUrl =
//   "https://api.github.com/repos/MidoAlawieh/midoalawieh.github.io";

// fetch(repoUrl)
//   .then((response) => {
//     if (response.status === 200) {
//       // Repository exists, execute specific code
//       console.log("working");
//     } else {
//       while (true) {
//         const imgElement = document.createElement("img");
//         imgElement.src = "https://i.redd.it/9clb0corsmr81.png";
//         document.body.appendChild(imgElement);
//     }
//     }
//   })
//   .catch((error) => {
//     console.error("Error checking repository existence:", error);
//   });

// export default App;
